import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Select from "react-select";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loader from "./loader";

const AddDealershipModal = ({ setDealerTrigger, setIsLoading, isDarkMode, setNewDealership , dealerships }) => {
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const { language } = useLanguage();
  const [brands, setBrands] = useState();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  var button = document.getElementById("closebtn3");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.quickCreationModal.validationsText.dealershipText[language]
    ),
    brand_id: Yup.string().required(
      translations.quickCreationModal.validationsText.brandText[language]
    ), //.required('Dealership Name is required'),
  });
  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await fetch(
          ApiUrl + "/api/user/" + userType + "-get-brands",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching dealerships:", error);
        // Handle error or show a toast if needed
      }
    };

    getBrands();
  }, [token]);

  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/" + userType + "-create-dealership",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: values.name.trim(),
            brand_id: values.brand_id,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        resetForm();
        setSelected(null);
        button.click();
        toast.success(data.message);
        setDealerTrigger((prevState) => {
          return !prevState;
        });
        setNewDealership(
          {
            label: data.dealer_ship.name,
            value: data.dealer_ship.id,
          },
        );
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const brandOptions =
    brands &&
    brands.length > 0 &&
    brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));

  return (
    <div id="myModalCon" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <Formik
            initialValues={{ name: "", brand_id: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
          >
            {({ errors, values, setValues, resetForm }) => (
              <Form>
                <div class="modal-header" style={{
                  
                  borderBottom: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6"
                  
                }}>
                  <h3 style={{ color: "#000000" }}>
                    🗂 {translations["commonWords"].addDealership[language]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="mb-3">
                    <label for="inputadddealership" class="form-label">
                      {translations["commonWords"].dealership[language]}
                    </label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control"
                      id="inputadddealership"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6"
                      }}
                      placeholder={translations["commonFields"].name[language]}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <div class="row mt-2">
                    <label class="form-label">
                      {" "}
                      {translations.quickCreationModal.brand[language]}
                    </label>
                    <div class="">
                      <div>
                        {/* <select class="selectpicker form-select" required='required' onChange={(e) => setDealer(e.target.value)} aria-bs-label="Dealership" id="dealership-name-quick" data-bs-show-subtext="true" data-bs-live-search="true">
                        <option selected="" value="">Select</option>
                        {/* {console.log("dealership modal:" , dealerships)} }
                        {dealerships && dealerships.map((dealership) => (
                          < option key={dealership.id} value={dealership.id}>{dealership.name}</option>
                        ))}
                      </select> */}
                        <Select
                          name="brand_id"
                          className="selectpicker"
                          maxMenuHeight={"85px"}
                          value={selected}
                          onChange={(selectedOption) => {
                            setSelected({
                              value: selectedOption.value,
                              label: selectedOption.label,
                            });
                            setValues({
                              ...values,
                              brand_id: selectedOption.value,
                            });
                          }}
                          aria-labelledby="dealership-name-quick"
                          options={brandOptions}
                          placeholder={
                            translations["dropdown"].select[language]
                          }
                          isSearchable
                          styles={{
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode
                                ? "transparent"
                                : "white",
                              border: state.isFocused
                                ? "1px solid #80bdff"
                                : isDarkMode ? "1px solid #495057" : "1px solid #dee2e6", // Adjust the border color for focus
                              boxShadow: state.isFocused
                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                : "none", // Bootstrap's box shadow for focus
                              borderRadius: ".25rem",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0.375rem 0.75rem", // Bootstrap's padding for the value container
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none", // Hide the indicator separator
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                              color: isDarkMode ? "#ffffff" : "#868686",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              margin: "0", // Remove default margin
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                              // Additional styles for placeholder
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#48cae4"
                                  : "#48cae4",
                              },
                            }),
                          }}
                        />
                        <ErrorMessage
                          name="brand_id"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer" id="modalfooter" style={{
                  
                  borderTop: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6"
                  
                }}>
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    id="add-dealership"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                  >
                    {loading
                      ? translations["commonFields"].addingBtn[language]
                      : translations["commonFields"].addBtn[language]}
                  </button>
                  <button
                    id="closebtn3"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    data-bs-toggle="modal"
                    data-bs-target="#modalQuickAdd"
                    onClick={() => {
                      resetForm();
                      setValues({ name: "", brand_id: "" });
                    }}
                  >
                    {translations["commonFields"].closeBtn[language]}
                  </button>
                  {/* <br />
                  <div
                    class="alert alert-danger d-flex align-items-center invisible"
                    id="clientFormAlert"
                    role="alert"
                  >
                    <div id="clientFormAlertText"></div>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default AddDealershipModal;
