import { useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "./loader";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";

const SendFormModal = ({ EventID, token, userEmails, setIsLoading, isDarkMode }) => {
  const userType = localStorage.getItem("userType");
  const { language } = useLanguage();
  const validationSchema = Yup.object().shape({
    due_date: Yup.string().required(translations.sendFormModal.form.requiredDueDate[language]),
    client_email: Yup.string()
      .email(translations.usersPage.createUser.validationText.email.invalid[language])
      .required(translations.usersPage.createUser.validationText.email.required[language]),
  });
  const [loading, setLoading ] = useState(false);
  var button = document.getElementById("closebtn4");
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/" + userType + "-create-childform",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parent_id: EventID,
            due_date: values.due_date,
            client_email: values.client_email,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setLoading(false);
        button.click();
        toast.success(data.message);
        handleCloseModal();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  function handleCloseModal() {
    const closeBtn = document.getElementById("closeModalBtnn");
    if (closeBtn) {
      closeBtn.click();
    }
  }

  return (
    <div id="sendFormModal" class="modal fade" tabindex="-1" >
      <div class="modal-dialog modal-dialog-centered" >
        <div class="modal-content" style={{
                              
                              background: isDarkMode ? "#212529" : "",
                              
                            }}>
          <Formik
            initialValues={{
              due_date: "",
              client_email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setValues, resetForm }) => (
              <Form>
                <div class="modal-header">
                  <h3>✉️ {translations.sendFormModal.title[language]} </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    id="closeModalBtnn"
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                  ></button>
                </div>
                <div class="modal-body">
                  
                  <div class="row">
                    <div class="mb-3">
                      <label for="date-but-from" class="form-label">
                        {translations.sendFormModal.form.due_Date[language]}
                      </label>
                      <input
                        name="due_date"
                        type="date"
                        class={`form-control ${isDarkMode ? "custom-date" : ""}`}
                        id="date-but-form"
                        value={values.due_date}
                        onChange={(e) =>
                          setValues({ ...values, due_date: e.target.value })
                        }
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          
                        }}
                      />
                      <ErrorMessage
                        name="due_date"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3">
                      <label for="client_email" class="form-label">
                      {translations.sendFormModal.form.email[language]}
                      </label>
                      <div
                        className={
                          errors.client_email && touched.client_email
                            ? "form-floating"
                            : "form-floating"
                        }
                      >
                        <Field
                          as="select"
                          name="client_email"
                          className="customerEmailDrop"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            
                          }}
                        >
                          <option value="" style={{
                              
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}>{translations.dropdown.select[language]}</option>

                          {userEmails &&
                            userEmails.length > 0 &&
                            userEmails.map((item) => (
                              <option key={item.email} value={item.email} style={{
                              
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}>
                                {item.email}
                              </option>
                            ))}
                        </Field>
                      </div>
                      <ErrorMessage
                        name="client_email"
                        component="div"
                        className="error text-danger"
                      />
                      {/* <input
                    type="email"
                    class="form-control"
                    id="client-form-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: "71px" }}
                  class="modal-footer"
                  id="modalfooter"
                >
                  <button
                    disabled={loading}
                    type="submit"
                    class="btn admin-btn btn-primary"
                    id="form-send-client"
                  >
                    
                    {loading ? translations.commonWords.sendingBtn[language] : translations.commonWords.sendBtn[language]}
                  </button>
                  <button
                    id="closebtn4"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => resetForm()}
                  >
                    {translations.commonFields.closeBtn[language]}
                  </button>
                  <div class="row" style={{ width: "100%" }}>
                    <div
                      class="alert alert-danger d-flex align-items-center invisible"
                      id="clientFormAlert"
                      role="alert"
                    >
                      <svg
                        class="bi flex-shrink-0 me-2"
                        width="24"
                        height="24"
                        role="img"
                        aria-label="Danger:"
                      >
                        {/*<use xlink:href="#exclamation-triangle-fill"></use>*/}
                      </svg>
                      <div id="clientFormAlertText"></div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {
        loading &&
        <Loader/>
      }
    </div>
  );
};

export default SendFormModal;
