import { useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Loader from "./loader";
// const validationSchema = Yup.object().shape({
//   due_date: Yup.string().required("Due Date is required"),
//   client_email: Yup.string()
//     .email("Invalid email address")
//     .required("Customer email is required"),
// });
const SendPdfModal = ({
  EventID,
  token,
  setIsLoading = false,
  formType,
  formUrl,
  week,
  setFormUrl,
  driveLink,
  dealer_name,
  isDarkMode
}) => {
  const userType = localStorage.getItem("userType");
  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);
  var button = document.getElementById("closebtn1");
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/send-mail-to-admins", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          drive_response:  driveLink,
          file_type: formType,
          filePath: formUrl,
          week: week,
          dealer_name:dealer_name.dealershipName
        }),
      });
      const data = await res.json();

      if (data.status === "success") {
        button.click();
        toast.success("PDF Sent Successfully!");
      } else {
        button.click();
        toast.success(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      button.click();
      toast.error("Something went wrong!");
    }
  };
  // const handleSubmit = async (values, { resetForm }) => {
  //   const res = await fetch(
  //     ApiUrl + "/api/user/" + userType + "-create-childform",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         parent_id: EventID,
  //         due_date: values.due_date,
  //         client_email: values.client_email,
  //       }),
  //     }
  //   );
  //   const data = await res.json();
  //   if (data.status === "success") {
  //     toast.success(data.message);
  //     handleCloseModal();
  //   }
  // };

  return (
    <div id="sendPdfModal" class="modal fade" tabindex="-1" >
      
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{
                              
                              background: isDarkMode ? "#212529" : "",
                              
                            }}>
          <Formik
            // initialValues={{
            //   due_date: "",
            //   client_email: "",
            // }}
            initialValues={{ data: "" }}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setValues, resetForm }) => (
              <Form>
                <div class="modal-header" style={{
                  
                  borderBottom: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6"
                  
                }}>
                  <h3>🎊🎉 {translations.sendPdfModal.title[language]}</h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    id="closeModalBtnn"
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <p style={{ fontWeight: "500" }}>
                    {translations.sendPdfModal.content[language]}
                  </p>
                </div>
                
                <div
                  style={{ height: "71px",borderTop: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6" }}
                  class="modal-footer"
                  id="modalfooter"

                >
                  <button
                    type="button"
                    class="btn admin-btn btn-primary"
                    id="form-send-client"
                    onClick={() => window.open(formUrl, "_blank")}
                    disabled={!formUrl || loading}
                  >
                    {translations.commonWords.downloadBtn[language]}
                  </button>
                  <button
                    type="submit"
                    class="btn admin-btn btn-primary"
                    id="form-send-client"
                    disabled={!formUrl || loading}
                  >
                    
                    {loading ? translations.commonWords.sendingBtn[language] : translations.commonWords.sendBtn[language]}
                  </button>
                  <button
                    id="closebtn1"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      resetForm();
                      setFormUrl(null);
                    }}
                  >
                    {translations.commonFields.closeBtn[language]}
                  </button>
                  <div class="row" style={{ width: "100%" }}>
                    <div
                      class="alert alert-danger d-flex align-items-center invisible"
                      id="clientFormAlert"
                      role="alert"
                    >
                      <svg
                        class="bi flex-shrink-0 me-2"
                        width="24"
                        height="24"
                        role="img"
                        aria-label="Danger:"
                      >
                        {/*<use xlink:href="#exclamation-triangle-fill"></use>*/}
                      </svg>
                      <div id="clientFormAlertText"></div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {
        loading && 
        <Loader/>
      }
    </div>
  );
};

export default SendPdfModal;
