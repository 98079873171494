import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
// import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "./assets/logo.png";
import { ApiUrl } from "./ApiUrl";
import useDealerships from "./useDealerships";
import Select from "react-select";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import logoDark from "./assets/Logo_fidgi_pale.png";

const Signup = ({
  setDisplayComponent,
  setIsLoading,
  isLoading,
  isDarkMode,
}) => {
  const [usertype, setUserType] = useState(null);
  const { language } = useLanguage();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.usersPage.createUser.validationText.name[language]
    ),
    email: Yup.string()
      .email(
        translations.usersPage.createUser.validationText.email.invalid[language]
      )
      .required(
        translations.usersPage.createUser.validationText.email.required[
          language
        ]
      ),
    phone: Yup.string().required(
      translations.usersPage.createUser.validationText.phone[language]
    ),
    userType: Yup.string().required(
      translations.usersPage.createUser.validationText.userType[language]
    ),
    designation: Yup.string().required(
      translations.usersPage.createUser.validationText.designation[language]
    ),
    dealerships:
      usertype === "2"
        ? Yup.array().min(
            1,
            translations.usersPage.createUser.validationText.dealership[
              language
            ]
          )
        : Yup.array().optional(),
    checktc: Yup.boolean()
      .required()
      .oneOf(
        [true],
        translations.usersPage.createUser.validationText.terms[language]
      ),
  });
  const navigate = useNavigate();
  const [loading, setLoadng] = useState(isLoading);

  // Check if the token exists in local storage
  const token = localStorage.getItem("token");
  const dealerships = useDealerships(token, true);

  // const [user, setUser] = useState('');

  // const handleRegister = () => {}
  // const userType = localStorage.getItem('userType')
  // useEffect(() => {
  //   // If the token exists, redirect to "/event-portal"
  //   if (!token) {
  //     navigate('/');
  //   }
  //   else {
  //     console.log('usertype Create user', userType)
  //     if (userType === 'client') {
  //       navigate('/client-form')
  //     } else if (userType === 'admin') {
  //     } else {
  //       toast.error('Something went wrong!');
  //       localStorage.removeItem('token')
  //       localStorage.removeItem('userType')
  //       navigate('/')
  //     }
  //   }
  //   //  else {
  //   //   if (userType !== 'Admin') {
  //   //     navigate('/client-form')
  //   //   }
  //   // }
  // }, [navigate]);

  const options = dealerships.map((dealership) => ({
    value: dealership.id,
    label: dealership.name,
  }));

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/admin-register-api", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          phone: values.phone,
          usertype: values.userType,
          designation: values.designation,
          dealerships: values.dealerships,
          pre_form: values.pre_form,
          ca_form: values.ca_form,
          gd_form: values.gd_form,
          client_submission: values.client_submission,
          user_language: values.user_language,
          tc: values.checktc.toString(),
        }),
      });

      // return res.json()
      const data = await res.json();
      // console.log(data);
      if (data.status === "success") {
        // console.log("Registered successfully");
        toast.success("Registered successful !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/event-portal");
      } else {
        // console.log(data.message)
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }

    // setLoadng(true);
    // await axios
    //   .post(`${process.env.REACT_APP_BASEURL}auth/register`, values)
    //   .then((res) => {
    //     setLoadng(false);
    //     resetForm();
    //     localStorage.setItem("token", res.data.token);
    //     localStorage.setItem("user", JSON.stringify(res.data.data));
    //     if (res.data.data.role === "admin") {
    //       navigate("/all-products");
    //     } else {
    //       navigate("/");
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadng(false);
    //     toast.error(error.response.data.message);
    //     console.error("Error updating user data:", error);
    //   });
  };

  // const token = localStorage.getItem("token");
  // const token = false;
  // useEffect(() => {
  //   if (token) {
  //     navigate("/");
  //   }
  // }, [token, navigate]);
  const languages = [
    {
      label: translations.commonWords.english[language],
      value: "en",
    },
    {
      label: translations.commonWords.french[language],
      value: "fr",
    },
  ];

  return (
    <div className="container">
      <div
        className="form-wrapper appcountainer"
        style={{ width: "500px", minHeight: "750px" }}
      >
        <main className="form-signin mx-auto ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              userType: "",
              designation: "",
              dealerships: [],
              pre_form: false,
              ca_form: false,
              gd_form: false,
              client_submission: false,
              user_language: "",
              checktc: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setValues }) => (
              <Form>
                <div className="d-flex justify-content-center mb-1">
                  {/* <Link to="/"> */}
                  <img
                    className="mb-1"
                    width="100px"
                    src={isDarkMode ? logoDark : logo}
                    alt=""
                  />
                  {/* </Link> */}
                </div>

                <div
                  className={`form-floating mb-3 ${
                    isDarkMode ? "dark-mode" : ""
                  }`}
                >
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error text-danger"
                  />
                  <label
                    htmlFor="floatingInput "
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      after: {
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    }}
                  >
                    {translations.commonFields.name[language]}{" "}
                  </label>
                </div>
                <div
                  className={`form-floating mb-3 ${
                    isDarkMode ? "dark-mode" : ""
                  }`}
                >
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error text-danger"
                  />
                  <label
                    htmlFor="floatingInput "
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      after: {
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    }}
                  >
                    {translations.commonFields.email[language]}
                  </label>
                </div>
                <div
                  className={`form-floating mb-3 ${
                    isDarkMode ? "dark-mode" : ""
                  }`}
                >
                  <Field
                    type="tel"
                    name="phone"
                    className="form-control"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error text-danger"
                  />
                  <label
                    htmlFor="floatingInput "
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      after: {
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    }}
                  >
                    {translations.commonFields.phone[language]}
                  </label>
                </div>
                {/* <div className={errors.password && touched.password ? "form-floating" : "form-floating mb-3"}>
                    <Field
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className={errors.confirmPassword && touched.confirmPassword ? "form-floating" : "form-floating mb-3"}>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="floatingPassword">Confirm Password</label>
                  </div> */}
                <div
                  className={`form-floating mb-3 ${
                    isDarkMode ? "dark-mode" : ""
                  }`}
                >
                  <Field
                    as="select"
                    name="userType"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                    onChange={(e) => {
                      setValues({ ...values, userType: e.target.value });
                      setUserType(e.target.value);
                    }}
                    class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                  >
                    <option
                      value=""
                      style={{
                        background: isDarkMode ? "#1B1F22" : "",
                        color: isDarkMode ? "#ffffff" : "",
                      }}
                    >
                      {translations.dropdown.select[language]}
                    </option>
                    <option
                      value="1"
                      style={{
                        background: isDarkMode ? "#1B1F22" : "",
                        color: isDarkMode ? "#ffffff" : "",
                      }}
                    >
                      Internal Users
                    </option>
                    <option
                      value="2"
                      style={{
                        background: isDarkMode ? "#1B1F22" : "",
                        color: isDarkMode ? "#ffffff" : "",
                      }}
                    >
                      Client
                    </option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  />
                  <label
                    htmlFor="userType"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      after: {
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    }}
                  >
                    {translations.usersPage.createUser.userType[language]}
                  </label>
                </div>
                <div
                  className={`form-floating mb-3 ${
                    isDarkMode ? "dark-mode" : ""
                  }`}
                >
                  <Field
                    as="select"
                    name="designation"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                    class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                  >
                    <option
                      value=""
                      style={{
                        background: isDarkMode ? "#1B1F22" : "",
                        color: isDarkMode ? "#ffffff" : "",
                      }}
                    >
                      {translations.dropdown.select[language]}
                    </option>
                    {values.userType === "1" && (
                      <>
                        <option
                          value="admin"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          Admin
                        </option>
                        <option
                          value="representative"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          Representative
                        </option>
                        <option
                          value="animator"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          Animator
                        </option>
                      </>
                    )}
                    {values.userType === "2" && (
                      <>
                        <option
                          value="manager"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          Manager
                        </option>
                        <option
                          value="employee"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          Employee
                        </option>
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="designation"
                    component="div"
                    className="error text-danger"
                  />
                  <label
                    htmlFor="designation"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      after: {
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    }}
                  >
                    {translations.usersPage.createUser.designation[language]}
                  </label>
                </div>

                {values.userType !== "1" && (
                  <>
                    <div
                      className="designationDrop"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    >
                      <label
                        className="pb-1"
                        style={{
                          marginTop: "-10px",
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations.commonWords.dealership[language]}
                      </label>
                      <div
                        className={`form-floating ${
                          isDarkMode ? "dark-mode" : ""
                        }`}
                      >
                        {/* <Field as="select" name="userType" className="form-select">
                    <option value="">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">Client</option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  /> */}

                        <Select
                          name="dealerships"
                          className="selectpicker"
                          maxMenuHeight={"150px"}
                          isClearable={false}
                          onChange={(selectedOption) => {
                            const selectedDealershipsId = selectedOption.map(
                              (data) => {
                                return { dealership_id: data.value };
                              }
                            );

                            setValues({
                              ...values,
                              dealerships: selectedDealershipsId,
                            });
                          }}
                          aria-labelledby="dealership-name-quick"
                          // isClearable
                          isMulti={true}
                          options={options}
                          placeholder={translations.dropdown.select[language]}
                          isSearchable
                          styles={{
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",

                              border: state.isFocused
                                ? "1px solid none"
                                : "1px solid none", // Adjust the border color for focus
                              boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                              borderRadius: ".25rem",
                              paddingTop: "0rem",

                              borderStyle: "none",

                              padding: "0px",
                              minHeight: "20px",
                              marginTop: "-8px",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none", // Hide the indicator separator
                            }),

                            dropdownIndicator: (provided) => ({
                              ...provided,
                              svg: {
                                fill: isDarkMode ? "#ffffff" : "#212529",
                                width: "18px",
                                height: "18px",
                              },
                              paddingRight: "10px",
                              // color: isDarkMode ? "#ffffff" : "#868686",
                            }),

                            menu: (provided) => ({
                              ...provided,
                              margin: "0", // Remove default margin
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                              // Additional styles for placeholder
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#34DBB4"
                                  : "#34DBB4",
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      name="dealerships"
                      component="div"
                      className="error text-danger"
                    />
                  </>
                )}
                <div
                  className="designationDrop mt-3"
                  style={{
                    backgroundColor: isDarkMode ? "transparent" : "",
                    color: isDarkMode ? "#ffffff" : "#000000",
                    border: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <label
                    class=""
                    style={{
                      marginTop: "-10px",
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      after: {
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    }}
                  >
                    {translations["commonFields"].language[language]}
                  </label>
                  <div
                    className={`form-floating ${isDarkMode ? "dark-mode" : ""}`}
                  >
                    <Select
                      placeholder={
                        translations["dropdown"].selectLanguage[language]
                      }
                      name="user_language"
                      className="selectpicker"
                      maxMenuHeight={"80px"}
                      onChange={(selectedOption) =>
                        setValues({
                          ...values,
                          user_language: selectedOption.value,
                        })
                      }
                      aria-labelledby="dealership-name-quick"
                      // isClearable
                      options={languages}
                      isSearchable
                      styles={{
                        input: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused
                            ? "1px solid none"
                            : "1px solid none", // Adjust the border color for focus
                          boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                          borderRadius: ".25rem",
                          paddingTop: "0rem",

                          borderStyle: "none",
                          backgroundColor: "transparent",
                          padding: "0px",
                          minHeight: "20px",
                          marginTop: "-8px",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none", // Hide the indicator separator
                        }),

                        dropdownIndicator: (provided) => ({
                          ...provided,
                          svg: {
                            fill: isDarkMode ? "#ffffff" : "#212529",
                            width: "18px",
                            height: "18px",
                          },
                          paddingRight: "10px",
                          // color: isDarkMode ? "#ffffff" : "#868686",
                        }),

                        menu: (provided) => ({
                          ...provided,
                          margin: "0", // Remove default margin
                          color: isDarkMode ? "#ffffff" : "black",
                          backgroundColor: isDarkMode ? "#1f2023" : "white",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                          // Additional styles for placeholder
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black",
                          backgroundColor: state.isHovered
                            ? "#80bdff"
                            : isDarkMode
                            ? "#1f2023"
                            : "white",
                          ":hover": {
                            backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
                {values.userType === "1" && (
                  <>
                    <div className="d-flex pt-1 align-items-center ">
                      <div
                        className="form-check my-1"
                        style={{ minWidth: "150px" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="pre_form"
                          name="pre_form"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              pre_form: e.target.checked,
                            });
                          }}
                        />

                        <label htmlFor="pre_form" class="form-check-label">
                          PRE Form
                        </label>
                      </div>
                      <div className="form-check mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="gd_form"
                          name="gd_form"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              gd_form: e.target.checked,
                            });
                          }}
                        />

                        <label htmlFor="gd_form" class="form-check-label">
                          DG Form
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center ">
                      <div
                        className="form-check mb-1"
                        style={{ minWidth: "150px" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="ca_form"
                          name="ca_form"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              ca_form: e.target.checked,
                            });
                          }}
                        />

                        <label htmlFor="ca_form" class="form-check-label">
                          CA Form
                        </label>
                      </div>
                      <div className="form-check mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="client_submission"
                          name="client_submission"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              client_submission: e.target.checked,
                            });
                          }}
                        />

                        <label
                          htmlFor="client_submission"
                          class="form-check-label"
                        >
                          Client Submissions
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className={
                    errors.checktc && touched.checktc
                      ? "form-check"
                      : "form-check my-3"
                  }
                >
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    id="checktc"
                    name="checktc"
                  />

                  <label htmlFor="checktc" class="form-check-label">
                    {translations.usersPage.createUser.agreeMessage[language]}
                  </label>
                  <ErrorMessage
                    name="checktc"
                    component="div"
                    className="error text-danger"
                  />
                </div>

                <button
                  className="w-100 admin-btn btn-lg btn-primary mb-2"
                  type="submit"
                  disabled={loading}
                >
                  {translations.usersPage.createUser.createUserBtn[language]}
                </button>
                <p className="text-center">
                  {translations.commonWords.goBack[language]}{" "}
                  <a
                    className="link-primary "
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/event-portal")}
                  >
                    {translations.EventsPortal.title.events[language]}
                  </a>
                  {/* <Link to="/event-portal" className="text-primary">
                  Event Portal
                </Link> */}
                </p>
              </Form>
            )}
          </Formik>
        </main>
      </div>
    </div>
  );
};

export default Signup;
