import React, { useState } from "react";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Navbar from "./navbar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";

function UserProfile({ isDarkMode, setIsDarkMode, setIsLoading }) {
  const { language } = useLanguage();
  const pageTranslations = translations["userProfilePage"];
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [edit, setEdit] = useState(false);
  const [valid, setValid] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  //   const userData = JSON.parse(localStorage.getItem("userData"));
  const { toggleLanguage } = useLanguage();

  const handleSubmit = async (values, { resetForm }) => {
    if (values.name === "") {
      setValid(true);
      return;
    } else {
      const oldData = {
        name: userData.name,
        phone: userData.phone,
        user_language: userData.user_language,
      };
      const newData = {
        name: values.name,
        phone: values.phone,
        user_language: values.user_language,
      };
      if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
        try {
          fetch(ApiUrl + "/api/user/edit-" + userType + "-profile", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              phone: values.phone,
              user_language: values.user_language,
            }),
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              toast.success(data.message);
              toggleLanguage();
              setUserData({
                ...userData,
                name: values.name,
                phone: values.phone,
                user_language: values.user_language,
              });

              localStorage.setItem(
                "userData",
                JSON.stringify({
                  ...userData,
                  name: values.name,
                  phone: values.phone,
                  user_language: values.user_language,
                })
              );
            });
        } catch (error) {
          toast.error("Something went wrong!");
        }
      } else {
        toast.error("Nothing to update");
      }
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      {userType === "client" && (
        <Navbar
          setToken={setToken}
          token={token}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          setIsLoading={setIsLoading}
        />
      )}
      <div
        className="Events row"
        style={{ minHeight: "80vh", overflow: "hidden" }}
      >
        <div class="container maincont col-5 mx-auto">
          <div id="app" class="appcountainer">
            <button
              type="button"
              className="btn btn-light mb-2"
              onClick={() =>
                navigate(
                  userType === "client"
                    ? "/client-dashboard"
                    : "/event-portal?page=events"
                )
              }
            >
              <i class="bi bi-arrow-left-circle"></i>{" "}
              {userType === "client"
                ? translations.viewClientForm.formListBtn[language]
                : translations.viewClientForm.eventListBtn[language]}
            </button>
            <div className="d-flex justify-content-between align-items-center my-2 ">
              <h2 id="h1">{pageTranslations.title[language]}</h2>
              <div>
                <button
                  className="admin-btn btn-lg btn-primary px-3 py-1"
                  type="button"
                  id="manage-notif-link"
                  data-bs-toggle="modal"
                  data-bs-target="#changePasswordModal"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                  // onClick={() => }
                >
                  {translations["topAndsidebar"].changePassword[language]}
                </button>
              </div>
            </div>
            <div className="pb-4">
              <Formik
                initialValues={{
                  id: userData.id,
                  name: userData.name,
                  email: userData.email,
                  phone: userData.phone,
                  user_language: userData.user_language,
                }}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, setValues }) => (
                  <Form>
                    <div
                      className={`form-floating ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                        onChange={(e) => {
                          setValues({ ...values, name: e.target.value });
                          setValid(false);
                        }}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="floatingInput"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations["commonFields"].name[language]}
                      </label>
                      {valid && (
                        <p className="text-danger">Name could not be empty</p>
                      )}
                    </div>
                    <div
                      className={`form-floating my-3 ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                      style={{ backgroundColor: isDarkMode ? "#4f5759" : "" }}
                      disabled
                    >
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        disabled
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                        }}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="floatingInput"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                        }}
                      >
                        {translations["commonFields"].email[language]}
                      </label>
                    </div>
                    <div
                      className={`form-floating my-3 ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      <Field
                        type="tel"
                        name="phone"
                        className="form-control"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="floatingInput "
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                        }}
                      >
                        {translations["commonFields"].phone[language]}
                      </label>
                    </div>
                    {
                      <div
                        className="designationDrop"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                      >
                        <label
                          class=""
                          style={{
                            marginTop: "-10px",
                            color: isDarkMode ? "#fff" : "#212529a6",
                          }}
                        >
                          {translations["commonFields"].language[language]}
                        </label>
                        <div
                          className={`form-floating ${
                            isDarkMode ? "dark-mode" : ""
                          }`}
                        >
                          {/* <Field as="select" name="userType" className="form-select">
                    <option value="">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">Client</option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  /> */}

                          <Select
                            placeholder={
                              translations["dropdown"].selectLanguage[language]
                            }
                            name="user_language"
                            className="selectpicker"
                            maxMenuHeight={"80px"}
                            onChange={(selectedOption) =>
                              setValues({
                                ...values,
                                user_language: selectedOption.value,
                              })
                            }
                            defaultValue={[
                              {
                                label:
                                  userData.user_language === "en"
                                    ? translations.commonWords.english[language]
                                    : translations.commonWords.french[language],
                                value: userData.user_language,
                              },
                            ]}
                            aria-labelledby="dealership-name-quick"
                            // isClearable
                            options={[
                              {
                                label:
                                  translations.commonWords.english[language],
                                value: "en",
                              },
                              {
                                label:
                                  translations.commonWords.french[language],
                                value: "fr",
                              },
                            ]}
                            isSearchable
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                border: state.isFocused
                                  ? "1px solid none"
                                  : "1px solid none", // Adjust the border color for focus
                                boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".25rem",
                                paddingTop: "0rem",

                                borderStyle: "none",
                                // backgroundColor: "transparent",
                                padding: "0px",
                                minHeight: "20px",
                                marginTop: "-8px",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),

                              dropdownIndicator: (provided) => ({
                                ...provided,
                                svg: {
                                  fill: isDarkMode ? "#ffffff" : "#868686",
                                  width: "18px",
                                  height: "18px",
                                },
                                paddingRight: "10px",
                                // color: isDarkMode ? "#ffffff" : "#868686",
                              }),

                              menu: (provided) => ({
                                ...provided,
                                margin: "0", // Remove default margin
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                // Additional styles for placeholder
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#48cae4"
                                    : "#48cae4",
                                },
                              }),
                            }}
                          />
                        </div>
                      </div>
                    }

                    <div className="mt-4 d-flex justify-content-end ">
                      <button
                        className="admin-btn btn-lg btn-primary"
                        type="submit"
                        // onClick={() => }
                      >
                        {translations["commonFields"].submitBtn[language]}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <ChangePasswordModal
            setIsLoading={setIsLoading}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
