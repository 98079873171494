import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Select from "react-select";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loader from "./loader";

const ModalAddDealer_CreateEvent = ({
  dealerships,
  setDealerTrigger,
  isEdit,
  editID,
  oldData,
  setOldData,
  setEditTrigger,
  setIsLoading,
  brands,
  setDealerships,
}) => {
  const brandOptions =
    brands &&
    brands.length > 0 &&
    brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));

  

  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const [selectedBrand, setSelectedBrand] = useState({
    value: oldData && oldData.brandId ? oldData.brandId  : "",
    label: oldData && oldData.brandName ? oldData.brandName : "",
  });

  useEffect(() => {
    setSelectedBrand({
      value: oldData && oldData.brandId ? oldData.brandId : "",
      label: oldData && oldData.brandName ? oldData.brandName : "",
    });
  }, [oldData]);
  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);

  var button = document.getElementById("edirDealershipClose");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.quickCreationModal.validationsText.dealershipText[language]
    ),
    brand_id: Yup.string().required(
      translations.quickCreationModal.validationsText.brandText[language]
    ), //.required('Dealership Name is required'),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/" + userType + "-edit-dealership",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            dealership_id: editID,
            name: values.name.trim(),
            brand_id: values.brand_id,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        resetForm();
        
        button.click();
        setEditTrigger((prev) => !prev);
        toast.success("Dealer edited successfully");
        const updatedDealerships = dealerships.map((data) =>
          data.id === editID
            ? {
                ...data,
                name: values.name,
                brand_id: selectedBrand.value,
                brand_name: selectedBrand.label,
              }
            : data
        );
        setDealerships(updatedDealerships);
        // setDealerTrigger((prevState) => {
        //   return !prevState;
        // });
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  // const handleDealership = async (event) => {
  //   event.preventDefault();
  //   if (!isEdit) {
  //     try {
  //       setIsLoading(true);
  //       const res = await fetch(
  //         ApiUrl + "/api/user/" + userType + "-create-dealership",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             name: dealership.trim(),
  //           }),
  //         }
  //       );
  //       // console.log('Res:', res);
  //       const data = await res.json();
  //       // console.log('data:', data);
  //       if (data.status === "success") {
  //         setDealership("");
  //         toast.success(data.message);
  //         // createDrive(data.delaer_ship_data.id);
  //         setDealerTrigger((prevState) => {
  //           // console.log("dealerTrigger", prevState);
  //           return !prevState;
  //         });
  //         handleCloseModal();

  //         // setDealerships((dealerships) => {
  //         //   console.log("dealerships in add dealership modal: ", dealerships)
  //         //   return [...dealerships, { "name": dealership }]
  //         // })
  //         // setDealerTrigger((previousState) => !previousState)
  //         // setDealerTrigger2((previousState)=> !previousState)
  //       } else {
  //         toast.error(data.message);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       toast.error("Something went wrong!");
  //     }
  //   } else {
  //     try {
  //       setIsLoading(true);
  //       const res = await fetch(
  //         ApiUrl + "/api/user/" + userType + "-edit-dealership",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             dealership_id: editID,
  //             name: dealership.trim(),
  //           }),
  //         }
  //       );
  //       // console.log('Res:', res);
  //       const data = await res.json();
  //       // console.log('data:', data);
  //       if (data.status === "success") {
  //         setEditDealerName(dealership);
  //         setEditTrigger((prev) => !prev);
  //         // Set the updated dealerships array in the state
  //         // setDealerships((dealerships) => dealerships.map((dealer) => {
  //         //   if (dealer.id === editID) {
  //         //     console.log(editDealerName, " ", editID)
  //         //     return { ...dealer, name: editDealerName };
  //         //   }
  //         //   return dealer;
  //         // }));
  //         setDealership("");
  //         toast.success("Dealer edited successfully");
  //         handleCloseModal();

  //         // setDealerships((dealerships) => {
  //         //   console.log("dealerships in add dealership modal: ", dealerships)
  //         //   return [...dealerships, { "name": dealership }]
  //         // })
  //         // setDealerTrigger((previousState) => !previousState)
  //         // setDealerTrigger2((previousState)=> !previousState)
  //       } else {
  //         toast.error(data.message);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       toast.error("Something went wrong!edittt");
  //     }
  //   }
  // };

  // const createDrive = async (dealerId) => {
  //   try {
  //     const res = await fetch(ApiUrl+'/api/user/createFolder-on-googleDrive', {
  //       method: 'POST',
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,

  //       },
  //       body: JSON.stringify({
  //         dealer_id: dealerId,
  //         dealer_name: dealership.trim()
  //       })
  //     })
  //     console.log('Res:', res);
  //     const data = await res.json();
  //     console.log('data:', data);
  //     if (data.status === 'success') {

  //     } else {
  //       toast.error(data.message)
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //     console.log("Creating drive link: ", error)
  //   }
  // }

  return (
    <div id="AddDealerModal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <Formik
            initialValues={{
              name: oldData && oldData.dealership ? oldData.dealership : "",
              brand_id: oldData && oldData.brandId ? oldData.brandId : "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
            enableReinitialize={true}
          >
            {({ errors, values, setValues, resetForm }) => (
              <Form>
                <div class="modal-header">
                  <h3 style={{ color: "#000000" }}>
                    🗂{" "}
                    {isEdit
                      ? translations.commonFields.editBtn[language]
                      : translations.commonFields.addBtn[language]}{" "}
                    {translations.commonWords.dealership[language]}{" "}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    id="closeModal-Btn"
                  ></button>
                </div>

                {oldData && (
                  <div class="modal-body">
                    <div class="mb-3">
                      <label for="inputadddealership" class="form-label">
                        {translations.commonWords.dealership[language]}
                      </label>
                      <Field
                        name="name"
                        type="text"
                        className="form-control"
                        id="inputadddealership"
                        placeholder={
                          translations["commonFields"].name[language]
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                    <div class="row mt-2">
                      <label class="form-label">
                        {" "}
                        {translations.quickCreationModal.brand[language]}
                      </label>
                      <div class="">
                        <div>
                          {/* <select class="selectpicker form-select" required='required' onChange={(e) => setDealer(e.target.value)} aria-bs-label="Dealership" id="dealership-name-quick" data-bs-show-subtext="true" data-bs-live-search="true">
                        <option selected="" value="">Select</option>
                        {/* {console.log("dealership modal:" , dealerships)} }
                        {dealerships && dealerships.map((dealership) => (
                          < option key={dealership.id} value={dealership.id}>{dealership.name}</option>
                        ))}
                      </select> */}

                          <Select
                            name="brand_id"
                            className="selectpicker"
                            maxMenuHeight={"80px"}
                            value={selectedBrand}
                            onChange={(selectedOption) => {
                              setSelectedBrand({
                                value: selectedOption.value,
                                label: selectedOption.label,
                              });
                              setValues({
                                ...values,
                                brand_id: selectedOption.value,
                              });
                            }}
                            aria-labelledby="dealership-name-quick"
                            options={brandOptions}
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isSearchable
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : "1px solid #ced4da", // Adjust the border color for focus
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".25rem",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem", // Bootstrap's padding for the value container
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                              }),
                              menu: (provided) => ({
                                ...provided,
                                margin: "0", // Remove default margin
                              }),
                            }}
                          />
                          <ErrorMessage
                            name="brand_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div class="modal-footer" id="modalfooter">
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    id="add-dealership-c" /*onClick={() => !dealership && toast.error("Failed! Dealership Name is required.")}*/
                  >
                    <span class="visually-hidden">Loading...</span>
                    {isEdit
                      ? translations.commonFields.editBtn[language]
                      : translations.commonFields.addBtn[language]}
                  </button>
                  <button
                    id="edirDealershipClose"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {translations.commonFields.closeBtn[language]}
                  </button>
                  <br />
                  <div
                    class="alert alert-danger d-flex align-items-center invisible"
                    id="clientFormAlert"
                    role="alert"
                  >
                    <div id="clientFormAlertText-c"></div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <ModalAdminQuickAdd/> */}
      </div>
    </div>
  );
};

export default ModalAddDealer_CreateEvent;
