import React from "react";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";

function Footer({ isDarkMode }) {
  const { language } = useLanguage();
  const currentYear = new Date().getFullYear();

  
  return (
    
      <p class={`footertext pt-3 ${isDarkMode ? "text-light " : "text-light "}`}>
        {translations.footerTxt1[language]}© {currentYear} FIDGI Communications.{" "}
        {translations.footerTxt2[language]}
      </p>
    
  );
}

export default Footer;
