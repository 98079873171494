import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import PaginatedItems from "./Pagination";
import Navbar from "./navbar";
import { Link, useNavigate } from "react-router-dom";

import Loader from "./loader";

import { useLanguage } from "./languageContext";
import translations from "./translation.json";

const ClientDashboard = ({ isDarkMode, setIsDarkMode }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [clientForms, setClientForms] = useState();
  const [clientInvitations, setClientInvitations] = useState();
  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const userType = localStorage.getItem("userType");
  const [activeSearch, setActiveSearch] = useState(false);
  const [key, setKey] = useState("");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const { language } = useLanguage();
  // Create state to track deleteClicked for each item
  // const [deleteStates, setDeleteStates] = useState();
  // const [editDealerName, setEditDealerName] = useState();
  // const [editTrigger, setEditTrigger] = useState(false);

  useEffect(() => {
    // If the token exists, redirect to "/event-portal"
    if (!token) {
      navigate("/", { replace: true });
      // navigate('/?parent_id=' + parent_id);
    } else {
      //     console.log('usertype clientForm', userType)
      if (userType === "client") {
        // navigate('/client-form')
      } else if (userType === "admin") {
        navigate("/event-portal", { replace: true });
      } else {
        toast.error("Something went wrong!");
        navigate("/", { replace: true });
      }
    }
  }, [token, userType]);

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setClientForms(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setIsLoading(true);
        try {
          const res = await fetch(
            ApiUrl + "/api/user/get-client_submittions?page=" + pageNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setToken(localStorage.getItem("token"));

          const data = await res.json();
          if (data.status === "success") {
            const newData = data.client_submittions_list.data;
            const newTotal = data.client_submittions_list.total;
            // Update the cache with new data
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));
            // Set state with new data
            const sortedData =
              newData && newData.length > 0
                ? newData.sort((a, b) => b.parent_id - a.parent_id)
                : [];
            setClientForms(sortedData);
            setTotal(newTotal);

            // setDeleteStates(newData.map(() => false))
          } else {
            toast.error(data.message);
          }
          setIsLoading(false);
        } catch (err) {
          toast.error("Something went wrong get dealership");
        } finally {
        }
      }
    };
    if (!activeSearch) {
      fetchData();
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    setIsDarkMode(userData.theme_mode !== "light" ? true : false);
  }, [pageNo, pageCache, clientForms]);

  // const handleDelete = async (id, index) => {
  //   const page =
  //     clientForms.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
  //   const searchParam = key ? "&search_parameter=" + key : "";
  //   try {
  //     const res = await fetch(
  //       ApiUrl +
  //       "/api/user/" +
  //       userType +
  //       "-delete-dealership?page=" +
  //       page +
  //       "" +
  //       searchParam,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({
  //           id: id,
  //         }),
  //       }
  //     );
  //     const data = await res.json();
  //     if (data.status === "success") {
  //       toast.success("Dealership deleted successful");
  //       const newData = data.client_submittions_list.data;
  //       const newTotal = data.client_submittions_list.total;

  //       setDealerTrigger((prev)=> !prev)
  //       setDealerships(newData);
  //       setTotal(newTotal);
  //       setPageCache({
  //         [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
  //       });
  //       if (key) {
  //         setSearchCache({
  //           [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
  //         });
  //       }

  //       if (activeSearch) {
  //         setPageNo(
  //           dealerships.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
  //         );
  //       }

  //       // Update delete state for the current item
  //       const newDeleteStates = [...deleteStates];
  //       newDeleteStates[index] = false;
  //       setDeleteStates(newDeleteStates);
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // };

  const handleSearch = async (pageNo, initial) => {
    // if (!initial && searchCache[pageNo]) {
    //   // Data already in cache, no need for API call
    //   const { cachedResponse, cachedTotal } = searchCache[pageNo];
    //   setDealerships(cachedResponse);
    //   setTotal(cachedTotal);
    // } else {
    //   // setFilterLoading(true);
    //   // setGetLoading(true);
    //   try {
    //     const res = await fetch(
    //       ApiUrl + "/api/user/" + userType + "-search-dealership?page=" + pageNo,
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //         },
    //         body: JSON.stringify({
    //           search_parameter: key,
    //         }),
    //       }
    //     );
    //     const data = await res.json();
    //     if (data.status === "success") {
    //       setActiveSearch(true);
    //       const newData = data.client_submittions_list.data;
    //       const newTotal = data.client_submittions_list.total;
    //       // Update the cache with new data
    //       setSearchCache((prevCache) => ({
    //         ...prevCache,
    //         [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
    //       }));
    //       // Set state with new data
    //       setDealerships(newData);
    //       setDealershipSearch(newData);
    //       console.log(newTotal);
    //       setTotal(newTotal);
    //       if (pageNo === 1) toast.success("Dealerships search successful");
    //       // setFilterLoading(false);
    //       // setGetLoading(false);
    //     } else {
    //       toast.error(data.message);
    //     }
    //   } catch (error) {
    //     toast.error("Something went wrong");
    //     // setFilterLoading(false);
    //     // setGetLoading(false);
    //   }
    // }
  };

  // useEffect(() => {
  //   if (editDealerID && dealerships) {
  //     const updatedDealerships = dealerships.map((dealer) => {
  //       if (dealer.id === editDealerID) {
  //         // Update t`he dealer name for the specific dealer ID
  //         console.log(editDealerName, " ", editDealerID)
  //         return { ...dealer, name: editDealerName };
  //       }
  //       return dealer;
  //     });
  //     console.log("editt")
  //     // Set the updated dealerships array in the state
  //     setDealerships(updatedDealerships);
  //     setPageCache({
  //       [pageNo]: { cachedResponse: updatedDealerships, cachedTotal: total },
  //     });
  //     if (key) {
  //       setSearchCache({
  //         [pageNo]: { cachedResponse: updatedDealerships, cachedTotal: total },
  //       });
  //     }
  //     setDealerTrigger((prev)=> !prev)
  //   }
  // }, [editTrigger]);

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[1];
    setPageNo(1);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setClientForms(cachedResponse);
    setKey("");
    toast.success("Clients search cleared");
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  function formatDate(date) {
    const datesArray = date.split(" / ");
    const lastDate = datesArray[datesArray.length - 1];

    return lastDate;
  }

  return (
    <div>
      {isLoading && (
        <div className="splash-screen">
          <Loader />
        </div>
      )}
      <div
        className="client-form"
        // style={{ minHeight: "91vh", height: "91vh" }}
      >
        <Navbar
          setToken={setToken}
          token={token}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
        />
        <div className="Events">
          <div class="container maincont">
            <div id="app" class="appcountainer2">
              {clientForms && (
                <>
                  {clientForms.length === 0 ? (
                    <h3 className="text-center">
                      {" "}
                      {translations.clientFormsList.noDataMessage[language]}
                    </h3>
                  ) : (
                    /*
                  <div class="headersec">
                    <div class="mb-2 d-flex">
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="searchInput"
                        placeholder="Search..."
                        value={key}
                        onChange={(e) => {
                          setKey(e.target.value);
                        }}
                      />
                      {activeSearch && (
                        <button className="bi bi-x-lg btn searchBtn"
                          style={{ padding: "6px 8px" }}
                          onClick={handleClearFilters}></button>
                      )}
                      <button
                        className="bi bi-search btn searchBtn"
                        style={{ padding: "6px 8px" }}
                        onClick={() => {
                          setPageCache((prevCache) => ({
                            ...prevCache,
                            pageNo: pageNo,
                          }));
                          setPageNo(1);
                          setSearchCache({});
                          if (key) {
                            handleSearch(1, true);
                          }
                        }}
                      />
                    </div>
                      </div>*/

                    clientForms.length !== 0 && (
                      <div>
                        <h3
                          id="h1"
                          className={`${isDarkMode ? "darkthead" : ""}`}
                        >
                          {translations.clientFormsList.title[language]}
                        </h3>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            maxHeight: "80vh",
                            height: "calc(80vh - 88px)",
                            overflow: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          <table class="table table-hover text-center align-middle">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  #
                                </th>
                                <th
                                  scope="col"
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  {
                                    translations.clientFormsList.eventId[
                                      language
                                    ]
                                  }
                                </th>
                                <th
                                  scope="col"
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  {translations.commonWords.firstDay[language]}
                                </th>
                                <th
                                  scope="col"
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  {translations.commonWords.lastDay[language]}
                                </th>
                                <th
                                  scope="col"
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  {
                                    translations.dealership.dealershipName[
                                      language
                                    ]
                                  }
                                </th>
                                {/* <th scope="col">Representative</th> */}
                                {/* <th scope="col">Week</th> */}
                                {/* <th scope="col"></th> */}
                                <th
                                  scope="col"
                                  colSpan={1}
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  {translations.commonWords.view[language]}
                                </th>
                                {/* <th scope="col"></th> */}
                              </tr>
                            </thead>

                            <tbody id="searchResults">
                              {clientForms &&
                                clientForms.map((form, index) => {
                                  if (form.invitation_accepted !== "1") {
                                    return (
                                      <tr class="result-box">
                                        <th class="custID" scope="row">
                                          {form.id}
                                        </th>
                                        <td class="dlrname">
                                          {form.parent_id}
                                        </td>
                                        <td class="dlrname">
                                          {form.first_day.split(" ")[0]}
                                        </td>
                                        <td class="dlrname">
                                          {formatDate(form.event_dates)}
                                        </td>
                                        <td class="dlrname">
                                          {form.dealershipName}
                                        </td>
                                        <td>
                                          <Link
                                            to={
                                              "/client-submission?id=" + form.id
                                            }
                                          >
                                            <a
                                              type="button"
                                              target="_blank"
                                              className={`btn btn-light drive-button ${
                                                language === "fr"
                                                  ? "text-center"
                                                  : "text-start "
                                              }`}
                                              style={{ width: "172px" }}
                                            >
                                              <i class="bi bi-send"></i>{" "}
                                              {form.submitted_on
                                                ? translations.commonWords
                                                    .viewSubmission[language]
                                                : translations.commonWords
                                                    .viewInvite[language]}
                                            </a>
                                          </Link>
                                        </td>
                                        {/* <td class="salesrep">{dealer.sales_representative}</td> */}
                                        {/* <td class="semainesearch">Week: {dealer.week}</td> */}
                                        {/* <td>
                          <a
                            type="button"
                            target="_blank"
                            class="btn btn-light drive-button"
                            href={dealer.drive_link}
                          >
                            <i class="bi bi-folder"></i> Drive
                          </a>
                        </td> */}

                                        {/* Edit*************************** */}
                                        {/* <td style={{ width: "170px" }}>
                          <button
                            type="button"
                            class="btn btn-light edit-button"
                            data-customer-id="405"
                            data-bs-toggle="modal"
                            data-bs-target="#AddDealerModal"
                            onClick={() => {
                              setEditDealerID(dealer.id)
                              setEditDealerName(dealer.name)
                              // setDisplayComponent("edit-event");
                              // setEditEventID(event.id)
                              // window.history.pushState(
                              //   {
                              //     id: "gallery",
                              //     randomData: window.Math.random(),
                              //   },
                              //   "title",
                              //   "/event-portal?page=edit-event&parent_id=" +
                              //   dealer.id
                              // );
                            }}
                          >
                            <i class="bi bi-pencil"></i> Edit
                          </button>
                        </td> */}

                                        {/* Delete**********************
                        <td style={{ width: "170px" }}>
                          <div class="btn-group" role="group">
                            {deleteStates[index] ?
                              <>
                                <button
                                  type="button"
                                  class="btn btn-success delete-button"
                                  data-customer-id="405"
                                  onClick={() => handleDelete(form.id, index)}
                                >
                                  Confirm
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-secondary cancel-button"
                                  onClick={() => setDeleteStates((prevStates) => {
                                    const newStates = [...prevStates];
                                    newStates[index] = false;
                                    return newStates;
                                  })}
                                >
                                  Cancel
                                </button>
                              </>
                              :
                              <button
                                type="button"
                                class="btn btn-danger before-delete-button"
                                dataset-button-state="delete"
                                onClick={() =>
                                  setDeleteStates((prevStates) => {
                                    const newStates = [...prevStates];
                                    newStates[index] = true;
                                    return newStates;
                                  })
                                }
                              >
                                <i class="bi bi-trash"></i> Delete
                              </button>
                            }
                          </div>
                        </td> */}
                                      </tr>
                                    );
                                  }
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}

              {total !== 0 && total > 10 && (
                <PaginatedItems
                  total={total}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  itemsPerPage={20}
                  handlePageChange={handlePageChange}
                  isDarkMode={isDarkMode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
