// LanguageContext.js
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "./ApiUrl";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const userType = localStorage.getItem("userType");
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const [language, setLanguage] = useState(
  //   userData && userData.user_language && userData.user_language !== null
  //     ? userData.user_language
  //     : userType === "admin"
  //     ? "fr"
  //     : "en"
  // );

  const [language, setLanguage] = useState(() => {
    if (userData && userData.user_language) {
      return userData.user_language;
    } else {
      return userType === "admin" ? "fr" : "en";
    }
  });

  useEffect(() => {
    if (userData && userData.user_language) {
      setLanguage(userData.user_language);
      // const fetchUser = async () => {
      //   const token = localStorage.getItem("token");
      //   if (token) {
      //     try {
      //       const res = await fetch(ApiUrl + "/api/user/loggeduser", {
      //         method: "GET",
      //         headers: {
      //           "Content-Type": "application/json",
      //           Authorization: `Bearer ${token}`,
      //         },
      //       });
      //       const data = await res.json();
      //       if (data.status === "success") {
      //         console.log(data);
      //         setLanguage(data.user.user_language);
      //       } else {
      //         localStorage.removeItem("token");
      //         localStorage.removeItem("userType");
      //         // navigate("/");
      //       }
      //       // return data;
      //     } catch (error) {
      //       localStorage.removeItem("token");
      //       localStorage.removeItem("userType");
      //       navigate("/");
      //     }
      //   }
      // };
      // fetchUser();
    } else {
      // If not available, set language based on userType
      setLanguage(userType === "admin" ? "fr" : "en");
    }
  }, [userType, userData]);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "en" ? "fr" : "en"));
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
