// LanguageToggle.js
import React from "react";
import { useLanguage } from "./languageContext";

const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <div
      className=""
      style={{
        fontSize: "14px",
      }}
    >
      {/* <div class="container">
        <div class="jumbotron">
          <div class="df-switch">
            <button
              type="button"
              class="btn btn-lg btn-toggle"
              data-toggle="button"
              aria-pressed="false"
              autocomplete="off"
            >
              <div class="inner-handle"></div>
              <div class="handle"></div>
            </button>
          </div>
        </div>
      </div> */}
      <button className="btn admin-btn btn-primary" onClick={toggleLanguage}>
        {language === "en" ? (
          <i className="flag-icon flag-icon-fr me-2"></i>
        ) : (
          <i className="flag-icon flag-icon-gb me-2"></i>
        )}
        {language === "en" ? "Fr" : "En"}
      </button>
    </div>
  );
};

export default LanguageToggle;
