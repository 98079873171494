import { useEffect, useState } from "react";
import logo from './assets/logo.png'
import logoDark from "./assets/Logo_fidgi_pale.png";
const Loader = () => {
  const loadingText = [
    "Les kangourous ne peuvent pas sauter en arrière, seulement en avant.",
    "La licorne est l'animal emblématique de l'Écosse depuis le 12ème siècle.",
    "Les renards roux peuvent tourner leur tête de 270 degrés.",
    "Le mot 'musique' vient du grec 'mousikē', qui signifie 'l'art des Muses'.",
    "Les accidents liés aux distributeurs de boissons sont deux fois plus nombreux que les attaques de requins aux États-Unis.",
    "Le cerveau humain a une capacité de stockage équivalente à environ 2,5 pétaoctets (soit 2,5 millions de gigaoctets).",
    "Les flamants roses naissent gris et acquièrent leur couleur rose en se nourrissant de crevettes et d'algues.",
    "Les girafes ont le même nombre de vertèbres cervicales que les humains : 7.",
    "Les caméléons peuvent voir à 360 degrés sans bouger leur tête.",
    "La Corée du Nord et la Finlande sont séparées par un seul pays.",
    "La pieuvre a 3 cœurs.",
    "Les pingouins ont une seule partenaire pour la vie. Ils proposent même des 'cadeaux' de pierres pour courtiser leur partenaire.",
    "Il y a 1,6 million de fourmis pour chaque être humain sur Terre.",
    "Les pingouins ont une seule partenaire pour la vie. Ils proposent même des 'cadeaux' de pierres pour courtiser leur partenaire.",
    "Si vous réduisez le Soleil à la taille d'un globule blanc et la Voie lactée à la même échelle, elle couvrirait toute la surface des États-Unis.",
    "50% de l'ADN humain est similaire à celui d'une banane.",
    "Le prénom 'Jessica' a été créé par William Shakespeare pour sa pièce 'Le Marchand de Venise'.",
    "Les mammouths se sont éteints près de 1 000 ans après la fin de la construction de la pyramide de Khéops.",
    "Les tatous donnent presque toujours naissance à des quadruplés du même sexe.",
    "Le mot 'musique' vient du grec 'mousikē', qui signifie 'l'art des Muses'.",
    "Nintendo vendait des cartes à jouer à ses débuts.",
    "Mario, le personnage emblématique de Nintendo, casse les blocs avec sa main et non avec sa tête.",
    "La Tour Eiffel grandit de 15 cm en été en raison de l'expansion métallique causée par la chaleur.",
    "Les rennes peuvent voir les rayons ultraviolets, ce qui les aide à repérer la mousse sous la neige.",
    "Les abeilles sont capables de reconnaître des visages humains.",
    "Le cœur d'une baleine bleue est assez grand pour qu'un homme puisse nager à l'intérieur de ses artères.",
    "Le lac Supérieur pourrait recouvrir l'Amérique de 30 cm d'eau.",
    "Les chauves-souris dorment à l'envers.",
    "Cette tâche est plus difficile que je ne le pensais."
  ];

  const [currentText, setCurrentText] = useState(loadingText[Math.floor(Math.random() * loadingText.length)]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * loadingText.length);
      setCurrentText(loadingText[randomIndex]);
    }, 2500);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [loadingText]);



  return (
    <div>
      <div id="loading" className="justify-content-center align-items-center">
        <img
          className="mb-4 loadingLogo"
          style={{textAlign: "center"}}
          width="200px"
              src={userData ? userData.theme_mode !== "light" ? logoDark : logo : logo}
          alt=""
        />
        <div id="load" style={{color:userData ? userData.theme_mode !== "light" ? "black" : "white" : "white"}}>
          <div class="letter" style={{ animationDelay: "0.2s" }}> </div>
          <div class="letter" style={{ animationDelay: "0.4s" }}>T</div>
          <div class="letter" style={{ animationDelay: "0.6s" }}>N</div>
          <div class="letter" style={{ animationDelay: "0.8s" }}>E</div>
          <div class="letter" style={{ animationDelay: "1.0s" }}>M</div>
          <div class="letter" style={{ animationDelay: "1.2s" }}>E</div>
          <div class="letter" style={{ animationDelay: "1.4s" }}>G</div>
          <div class="letter" style={{ animationDelay: "1.6s" }}>R</div>
          <div class="letter" style={{ animationDelay: "1.8s" }}>A</div>
          <div class="letter" style={{ animationDelay: "2.0s" }}>H</div>
          <div class="letter" style={{ animationDelay: "2.2s" }}>C</div>
          <div class="letter" style={{ animationDelay: "2.4s" }}> </div>
        </div>
        <div id="loadingText">{currentText}</div>
      </div>
    </div>

  );
}

export default Loader;