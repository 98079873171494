import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import PaginatedItems from "./Pagination";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";

const Users = ({ setDisplayComponent, setIsLoading, isDarkMode }) => {
  const token = localStorage.getItem("token");
  const [users, setusers] = useState();
  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const userType = localStorage.getItem("userType");
  const [activeSearch, setActiveSearch] = useState(false);
  const [key, setKey] = useState("");
  const { language } = useLanguage();
  const userTypes = [
    {
      label: "Admin",
      value: "1",
    },
    {
      label: "Client",
      value: "2",
    },
    {
      label: "Representative",
      value: "representative",
    },
    {
      label: "Manager",
      value: "manager",
    },
    {
      label: "Animator",
      value: "animator",
    },
    {
      label: "Employee",
      value: "employee",
    },
    {
      label: "User",
      value: "4",
    },
  ];

  // Create state to track deleteClicked for each item
  const [deleteStates, setDeleteStates] = useState();
  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setusers(cachedResponse);
        setTotal(cachedTotal);
      } else {
        try {
          setIsLoading(true);
          const res = await fetch(
            ApiUrl + "/api/user/" + userType + "-get-all-users?page=" + pageNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log("Get users res: ", res);
          const data = await res.json();
          // console.log("Get users data: ", data)
          // console.log(result)
          if (data.status === "success") {
            const newData = data.list_of_users.data;
            const newTotal = data.list_of_users.total;
            // Update the cache with new data
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));
            // Set state with new data
            setusers(newData);
            setTotal(newTotal);
            setDeleteStates(newData.map(() => false));
            setIsLoading(false);
          } else {
            toast.error(data.message);
          }
          setIsLoading(false);
        } catch (err) {
          toast.error("Something went wrong");
        } finally {
        }
      }
    };
    if (!activeSearch) {
      fetchData();
    }
  }, [pageNo, pageCache, users]);

  const handleDelete = async (id, index) => {
    const page =
      users.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setIsLoading(true);
      const res = await fetch(
        ApiUrl +
          "/api/user/" +
          userType +
          "-delete-users?page=" +
          page +
          "" +
          searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success("User deleted successful");
        setDeleteStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = false;
          return newStates;
        });
        const newData = data.users_list.data;
        const newTotal = data.users_list.total;

        setusers(newData);
        setTotal(newTotal);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        if (key) {
          setSearchCache({
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          });
        }

        if (activeSearch) {
          setPageNo(
            users.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleResend = async (email) => {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/send-set-password-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success("Email sent successfully");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      setusers(cachedResponse);
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/" + userType + "-search-users?page=" + pageNo,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              search_parameter: key,
            }),
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.event_list.data;
          const newTotal = data.event_list.total;
          // Update the cache with new data
          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setusers(newData);
          setTotal(newTotal);
          // if (pageNo === 1) toast.success("Student search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[1];
    setPageNo(1);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setusers(cachedResponse);
    setKey("");
    // toast.success("Student search cleared");
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  return (
    <div className="Events">
      <div class="container maincont">
        <div id="app" class="appcountainer2">
          <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
            {translations.usersPage.title[language]}
          </h2>
          <div class="headersec">
            <div class="mb-2 d-flex">
              <input
                type="text"
                style={{
                  borderColor: "#dee2e6",
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                }}
                autocomplete="off"
                className={`form-control ${isDarkMode ? "dark-mode" : ""}`}
                id="searchInput"
                placeholder={
                  translations.commonFields.searchbar.search[language]
                }
                value={key}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
              {activeSearch && (
                <button
                  className="bi bi-x-lg btn searchBtn"
                  style={{ padding: "6px 8px" }}
                  onClick={handleClearFilters}
                ></button>
              )}
              <button
                className="bi bi-search btn searchBtn"
                style={{ padding: "6px 8px" }}
                onClick={() => {
                  setPageCache((prevCache) => ({
                    ...prevCache,
                    pageNo: pageNo,
                  }));
                  setPageNo(1);
                  setSearchCache({});
                  if (key) {
                    handleSearch(1, true);
                  }
                }}
              ></button>
            </div>
          </div>
          <div
            class="bodysecsearch"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <table class="table table-hover text-center align-middle">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.commonFields.name[language]}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.commonFields.email[language]}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.usersPage.createUser.userType[language]}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.usersPage.createUser.status[language]}
                  </th>
                  {/* <th scope="col"></th> */}
                  <th
                    scope="col"
                    colSpan={userType === "admin" ? 3 : 1}
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    Actions
                  </th>
                  {/* <th scope="col"></th> */}
                </tr>
              </thead>
              <tbody id="searchResults">
                {users &&
                  users.map((user, index) => {
                    return (
                      <tr class="result-box">
                        <th class="custID" scope="row">
                          {user.id}
                        </th>
                        <td class="dlrname">{user.name}</td>
                        <td class="salesrep">{user.email}</td>
                        <td class="semainesearch">
                          {userTypes.find(
                            (type) => type.value === user.user_type
                          )?.label || "Unknown"}
                        </td>
                        <td class="semainesearch" id={user.account_activate === "Yes" ? "statusActive" : "statusInactive"} >
                          {user.account_activate === "Yes"
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td>
                          {user.password_set === null && (
                            <button
                              type="button"
                              class="btn btn-light copy-button"
                              data-customer-id="405"
                              onClick={() => handleResend(user.email)}
                            >
                              <i class="bi bi-send"></i>{" "}
                              {translations.commonFields.resendEmail[language]}
                            </button>
                          )}
                        </td>
                        {userType === "admin" && (
                          <>
                            <td>
                              <button
                                type="button"
                                class="btn btn-light edit-button"
                                data-customer-id="405"
                                onClick={() => {
                                  setDisplayComponent("edit-event");

                                  window.history.pushState(
                                    {
                                      id: "gallery",
                                      randomData: window.Math.random(),
                                    },
                                    "title",
                                    "/event-portal?page=edit-user&user_id=" +
                                      user.id
                                  );
                                }}
                              >
                                <i class="bi bi-pencil me-1"></i>
                                {translations.commonFields.editBtn[language]}
                              </button>
                            </td>

                            <td style={{ width: "170px" }}>
                              <div class="btn-group" role="group">
                                {deleteStates && deleteStates[index] ? (
                                  <>
                                    <button
                                      type="button"
                                      class="btn btn-success delete-button"
                                      data-customer-id="405"
                                      onClick={() =>
                                        handleDelete(user.id, index)
                                      }
                                    >
                                      {
                                        translations.commonFields.confirm[
                                          language
                                        ]
                                      }
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-secondary cancel-button"
                                      onClick={() =>
                                        setDeleteStates((prevStates) => {
                                          const newStates = [...prevStates];
                                          newStates[index] = false;
                                          return newStates;
                                        })
                                      }
                                    >
                                      {
                                        translations.commonFields.cancel[
                                          language
                                        ]
                                      }
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger before-delete-button"
                                    dataset-button-state="delete"
                                    onClick={() => {
                                      setDeleteStates((prevStates) => {
                                        const newStates = [...prevStates];
                                        newStates[index] = true;
                                        return newStates;
                                      });
                                    }}
                                  >
                                    <i class="bi bi-trash"></i>{" "}
                                    {translations.commonFields.delete[language]}
                                  </button>
                                )}
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {total !== 0 && total > 10 && (
            <PaginatedItems
              total={total}
              pageNo={pageNo}
              setPageNo={setPageNo}
              handlePageChange={handlePageChange}
              isDarkMode={isDarkMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
