import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
// import axios from "axios";
import logo from "./assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "./ApiUrl";
import Loader from "./loader";
import logoDark from "./assets/Logo_fidgi_pale.png";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  // .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
});

const Login = ({isDarkMode}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  // useLocation gives you access to the current URL location
  const location = useLocation();
  const parent_id = location.state?.parent_id || null;
  // Get the value of the 'reset_token' query parameter
  // const queryParams = new URLSearchParams(location.search);
  // const parent_id = queryParams.get('parent_id');

  const dashboardTypes = ["1", "manager", "representative", "animator"];

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        }),
      });
      const data = await res.json();

      if (data.status === "success") {
        toast.success("Login successful !", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setToken(data.token);
        localStorage.setItem("token", data.token);
        
      } else {
        setLoading(false);
        toast.error("Authentication Failed !");
      }
      // console.log(data);

      // if (data.token) {
      //   navigate('/event-portal');
      // }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }

    // setLoadng(true);
    // await axios
    //   .post(`${process.env.REACT_APP_BASEURL}auth/login`, values)
    //   .then((res) => {
    //     setLoadng(false);
    //     resetForm();
    //     localStorage.setItem("token", res.data.token);
    //     localStorage.setItem("user", JSON.stringify(res.data.data));
    //     if (res.data.data.role === "admin") {
    //       navigate("/all-products");
    //     } else {
    //       navigate("/");
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadng(false);
    //     toast.error(error.response.data.message);
    //     console.error("Error updating user data:", error);
    //   });
  };
  useEffect(() => {
    const handleFetchUserData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/loggeduser", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          if (token) {
            localStorage.setItem(
              "userType",
              data.user.user_type === "1"
                ? "admin"
                : data.user.user_type === "2"
                ? "client"
                : null
            );
            
            localStorage.setItem("userData", JSON.stringify(data.user));
            

            if (dashboardTypes.includes(data.user.user_type)) {
              navigate("/event-portal", { replace: true });
            } else if (data.user.user_type === "2") {
              if (parent_id) {
                navigate("/client-form?parent_id=" + parent_id, {
                  replace: true,
                });
              } else {
                navigate("/client-dashboard", { replace: true });
              }
            } else {
              toast.error("Invalid User Type");
              localStorage.removeItem("token");
              localStorage.removeItem("userData");
            }
            // const userData = handleFetchUserData();
            // if (userData.user_type === 'admin') {
          }
        } else {
          toast.error("Login Failed!");
        }
        // return data;
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong.........");
      }
    };
    token && handleFetchUserData();
  }, [token]);

  return (
    <div>
      <div className="dark-light d-flex align-items-center" style={{ paddingTop: "50px" }}>
        <div
          className="form-wrapper appcountainer dark"
          style={{ width: "500px", minHeight: "650px" }}
        >
          <main className="form-signin mx-auto">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="d-flex justify-content-center">
                  <Link to="/">
                    <img
                      className="mb-4"
                      width="100px"
                      src={isDarkMode ? logoDark : logo}
                      alt=""
                    />
                  </Link>
                </div>

                <div className="form-floating">
                  <Field type="email" name="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error text-danger"
                  />
                  <label htmlFor="floatingInput ">Email address</label>
                </div>
                <div className="form-floating mt-3">
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error text-danger"
                  />
                  <label htmlFor="floatingPassword">Password</label>
                </div>

                <button
                  className="w-100 admin-btn btn-lg btn-primary mt-3"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Signing in..." : "Sign in"}
                </button>
                <p className="text-center mt-3">
                  Forgot your password?{" "}
                  <Link to="/confirm-email" className="text-primary">
                    Reset Password
                  </Link>
                </p>
              </Form>
            </Formik>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Login;
