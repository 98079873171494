import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
// import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "./assets/logo.png";
import { ApiUrl } from "./ApiUrl";
import useDealerships from "./useDealerships";
import Select from "react-select";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import logoDark from "./assets/Logo_fidgi_pale.png";

const EditUser = ({ setDisplayComponent, setIsLoading, isDarkMode }) => {
  const { language } = useLanguage();
  const [usertype, setUserType] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoadng] = useState(false);
  const [formValues, setFormValues] = useState();
  const [oldDealerships, setOldDealerships] = useState(null);
  const [selectedDealerships, setselectedDealerships] = useState(null);
  const userType = localStorage.getItem("userType");
  // Check if the token exists in local storage
  const token = localStorage.getItem("token");
  const [editUserID, setEditUserID] = useState();
  var currentUrl = window.location.href;
  const dealerships = useDealerships(token, true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.usersPage.createUser.validationText.name[language]
    ),
    email: Yup.string()
      .email(
        translations.usersPage.createUser.validationText.email.invalid[language]
      )
      .required(
        translations.usersPage.createUser.validationText.email.required[
          language
        ]
      ),
    phone: Yup.string().required(
      translations.usersPage.createUser.validationText.phone[language]
    ),
    userType: Yup.string().required(
      translations.usersPage.createUser.validationText.userType[language]
    ),
    designation:
      usertype === "2"
        ? Yup.string().required(
            translations.usersPage.createUser.validationText.designation[
              language
            ]
          )
        : Yup.string().nullable(),
    dealerships:
      usertype === "2"
        ? Yup.array().min(
            1,
            translations.usersPage.createUser.validationText.dealership[
              language
            ]
          )
        : Yup.array().optional(),
  });

  const options = dealerships.map((dealership) => ({
    value: dealership.id,
    label: dealership.name,
  }));
  const languages = [
    {
      label: translations.commonWords.english[language],
      value: "en",
    },
    {
      label: translations.commonWords.french[language],
      value: "fr",
    },
  ];
  useEffect(() => {
    // Get the current URL
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");

    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);

        setEditUserID(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchFormData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/" + userType + "-get-user",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: editUserID,
            }),
          }
        );

        const data = await res.json();

        // console.log("data get ", data)
        if (data.status === "success") {
          setFormValues(data.user_data);
          setUserType(data.user_data.user_type);
          setOldDealerships(data.user_dealership);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error);
      }
    };

    fetchFormData();
  }, [editUserID]);

  // console.log(editUserID);
  // const [user, setUser] = useState('');

  // const handleRegister = () => {}
  // const userType = localStorage.getItem('userType')
  // useEffect(() => {
  //   // If the token exists, redirect to "/event-portal"
  //   if (!token) {
  //     navigate('/');
  //   }
  //   else {
  //     console.log('usertype Create user', userType)
  //     if (userType === 'client') {
  //       navigate('/client-form')
  //     } else if (userType === 'admin') {
  //     } else {
  //       toast.error('Something went wrong!');
  //       localStorage.removeItem('token')
  //       localStorage.removeItem('userType')
  //       navigate('/')
  //     }
  //   }
  //   //  else {
  //   //   if (userType !== 'Admin') {
  //   //     navigate('/client-form')
  //   //   }
  //   // }
  // }, [navigate]);

  const handleSubmit = async (values, { resetForm }) => {
    const oldData = {
      user_id: editUserID,
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      userType: formValues.user_type,
      designation: formValues.designation,
      user_language: formValues.user_language,
      dealerships: formValues.dealerships,
      pre_form: formValues.pre_form
        ? formValues.pre_form === "1"
          ? true
          : false
        : false,
      ca_form: formValues.ca_form
        ? formValues.ca_form === "1"
          ? true
          : false
        : false,
      gd_form: formValues.gd_form
        ? formValues.gd_form === "1"
          ? true
          : false
        : false,
      client_submission: formValues.client_submission
        ? formValues.client_submission === "1"
          ? true
          : false
        : false,
    };

    if (JSON.stringify(oldData) !== JSON.stringify(values)) {
      try {
        const res = await fetch(ApiUrl + "/api/user/admin-edit-user-profile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: values.user_id,
            name: values.name,
            email: values.email,
            phone: values.phone,
            userType: values.userType,
            user_language: values.user_language,
            designation: values.designation,
            dealerships: values.dealerships,
            pre_form: values.pre_form,
            ca_form: values.ca_form,
            gd_form: values.gd_form,
            client_submission: values.client_submission,
          }),
        });

        // return res.json()
        const data = await res.json();

        if (data.status === "success") {
          // console.log("Registered successfully");
          toast.success("User Updated Successfully !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("?page=manage-users");
        } else {
          // console.log(data.message)
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Nothing to update");
      navigate("?page=manage-users");
    }

    // setLoadng(true);
    // await axios
    //   .post(`${process.env.REACT_APP_BASEURL}auth/register`, values)
    //   .then((res) => {
    //     setLoadng(false);
    //     resetForm();
    //     localStorage.setItem("token", res.data.token);
    //     localStorage.setItem("user", JSON.stringify(res.data.data));
    //     if (res.data.data.role === "admin") {
    //       navigate("/all-products");
    //     } else {
    //       navigate("/");
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadng(false);
    //     toast.error(error.response.data.message);
    //     console.error("Error updating user data:", error);
    //   });
  };

  // const token = localStorage.getItem("token");
  // const token = false;
  // useEffect(() => {
  //   if (token) {
  //     navigate("/");
  //   }
  // }, [token, navigate]);
  const selected =
    options &&
    options.filter((item) => {
      return (
        oldDealerships &&
        oldDealerships.some(
          (dealership) => dealership.dealership_id === item.value
        )
      );
    });

  return (
    <div className="container">
      <div
        className="form-wrapper appcountainer"
        style={{ width: "500px", minHeight: "750px" }}
      >
        <main className="form-signin mx-auto ">
          {formValues && (
            <Formik
              initialValues={{
                user_id: editUserID,
                name: editUserID ? formValues.name : "",
                email: editUserID ? formValues.email : "",
                phone: editUserID ? formValues.phone : "",
                userType: editUserID ? formValues.user_type : "",
                user_language: editUserID ? formValues.user_language : "",
                designation: editUserID ? formValues.designation : "",
                dealerships: editUserID ? oldDealerships : [],
                pre_form: editUserID
                  ? formValues.pre_form
                    ? formValues.pre_form === "1"
                      ? true
                      : false
                    : false
                  : "",
                ca_form: editUserID
                  ? formValues.ca_form
                    ? formValues.ca_form === "1"
                      ? true
                      : false
                    : false
                  : "",
                gd_form: editUserID
                  ? formValues.gd_form
                    ? formValues.gd_form === "1"
                      ? true
                      : false
                    : false
                  : "",
                client_submission: editUserID
                  ? formValues.client_submission
                    ? formValues.client_submission === "1"
                      ? true
                      : false
                    : false
                  : "",
              }}
              enableReinitialize={editUserID ? true : false}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setValues }) => (
                <Form>
                  <div className="d-flex justify-content-center mb-3">
                    {/* <Link to="/"> */}
                    <img
                      className="mb-1"
                      width="100px"
                      src={isDarkMode ? logoDark : logo}
                      alt=""
                    />
                    {/* </Link> */}
                  </div>

                  <div
                    className={`form-floating mb-3 ${
                      isDarkMode ? "dark-mode" : ""
                    }`}
                  >
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error text-danger"
                    />
                    <label
                      htmlFor="floatingInput "
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        after: {
                          backgroundColor: isDarkMode ? "transparent" : "",
                        },
                      }}
                    >
                      {translations.commonFields.name[language]}{" "}
                    </label>
                  </div>
                  <div
                    className={`form-floating mb-3 ${
                      isDarkMode ? "dark-mode" : ""
                    }`}
                  >
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error text-danger"
                    />
                    <label
                      htmlFor="floatingInput "
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        after: {
                          backgroundColor: isDarkMode ? "transparent" : "",
                        },
                      }}
                    >
                      {translations.commonFields.email[language]}
                    </label>
                  </div>
                  <div
                    className={`form-floating mb-3 ${
                      isDarkMode ? "dark-mode" : ""
                    }`}
                  >
                    <Field
                      type="tel"
                      name="phone"
                      className="form-control"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error text-danger"
                    />
                    <label
                      htmlFor="floatingInput "
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        after: {
                          backgroundColor: isDarkMode ? "transparent" : "",
                        },
                      }}
                    >
                      {translations.commonFields.phone[language]}
                    </label>
                  </div>
                  {/* <div className={errors.password && touched.password ? "form-floating" : "form-floating mb-3"}>
                    <Field
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className={errors.confirmPassword && touched.confirmPassword ? "form-floating" : "form-floating mb-3"}>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="floatingPassword">Confirm Password</label>
                  </div> */}
                  <div
                    className={`form-floating mb-3 ${
                      isDarkMode ? "dark-mode" : ""
                    }`}
                  >
                    <Field
                      as="select"
                      name="userType"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                      onChange={(e) => {
                        setValues({ ...values, userType: e.target.value });
                        setUserType(e.target.value);
                      }}
                      class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                    >
                      <option
                        value=""
                        style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}
                      >
                        {translations.dropdown.select[language]}
                      </option>
                      <option
                        value="1"
                        style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}
                      >
                        Internal Users
                      </option>
                      <option
                        value="2"
                        style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}
                      >
                        Client
                      </option>
                    </Field>
                    <ErrorMessage
                      name="userType"
                      component="div"
                      className="error text-danger"
                    />
                    <label
                      htmlFor="userType"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        after: {
                          backgroundColor: isDarkMode ? "transparent" : "",
                        },
                      }}
                    >
                      {translations.usersPage.createUser.userType[language]}
                    </label>
                  </div>
                  <div
                    className={`form-floating mb-3 ${
                      isDarkMode ? "dark-mode" : ""
                    }`}
                  >
                    <Field
                      as="select"
                      name="designation"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                      class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                    >
                      <option
                        value=""
                        style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}
                      >
                        {translations.dropdown.select[language]}
                      </option>
                      {values.userType === "1" && (
                        <>
                          <option
                            value="admin"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            Admin
                          </option>
                          <option
                            value="representative"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            Representative
                          </option>
                          <option
                            value="animator"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            Animator
                          </option>
                        </>
                      )}
                      {values.userType === "2" && (
                        <>
                          <option
                            value="manager"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            Manager
                          </option>
                          <option
                            value="employee"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            Employee
                          </option>
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="designation"
                      component="div"
                      className="error text-danger"
                    />
                    <label
                      htmlFor="designation"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        after: {
                          backgroundColor: isDarkMode ? "transparent" : "",
                        },
                      }}
                    >
                      {translations.usersPage.createUser.designation[language]}
                    </label>
                  </div>
                  {values.userType !== "1" && (
                    <>
                      <div
                        className="designationDrop"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                      >
                        <label
                          className="pb-1"
                          style={{
                            marginTop: "-10px",
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            after: {
                              backgroundColor: isDarkMode ? "transparent" : "",
                            },
                          }}
                        >
                          {translations.commonWords.dealership[language]}
                        </label>
                        <div
                          className={`form-floating ${
                            isDarkMode ? "dark-mode" : ""
                          }`}
                        >
                          {/* <Field as="select" name="userType" className="form-select">
                    <option value="">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">Client</option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  /> */}

                          <Select
                            name="dealerships"
                            className="selectpicker"
                            maxMenuHeight={"100px"}
                            value={options.filter((item) => {
                              // Check if any dealership_id in values.dealerships matches the item's value
                              return values.dealerships.some(
                                (dealership) =>
                                  dealership.dealership_id === item.value
                              );
                            })}
                            isMulti={true}
                            onChange={(selectedOption) => {
                              const selectedDealershipsId = selectedOption.map(
                                (data) => {
                                  return { dealership_id: data.value };
                                }
                              );
                              setValues({
                                ...values,
                                dealerships: selectedDealershipsId,
                              });
                            }}
                            aria-labelledby="dealership-name-quick"
                            isClearable={false}
                            options={options}
                            placeholder={translations.dropdown.select[language]}
                            isSearchable
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: "transparent",

                                border: state.isFocused
                                  ? "1px solid none"
                                  : "1px solid none", // Adjust the border color for focus
                                boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".25rem",
                                paddingTop: "0rem",

                                borderStyle: "none",

                                padding: "0px",
                                minHeight: "20px",
                                marginTop: "-8px",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),

                              dropdownIndicator: (provided) => ({
                                ...provided,
                                svg: {
                                  fill: isDarkMode ? "#ffffff" : "#212529",
                                  width: "18px",
                                  height: "18px",
                                },
                                paddingRight: "10px",
                                // color: isDarkMode ? "#ffffff" : "#868686",
                              }),

                              menu: (provided) => ({
                                ...provided,
                                margin: "0", // Remove default margin
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                // Additional styles for placeholder
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#34DBB4"
                                    : "#34DBB4",
                                },
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        name="dealerships"
                        component="div"
                        className="error text-danger"
                      />
                    </>
                  )}
                  <div
                    className="designationDrop mt-3"
                    style={{
                      backgroundColor: isDarkMode ? "transparent" : "",
                      color: isDarkMode ? "#ffffff" : "#000000",
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    <label
                      class=""
                      style={{
                        marginTop: "-10px",
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        after: {
                          backgroundColor: isDarkMode ? "transparent" : "",
                        },
                      }}
                    >
                      {translations["commonFields"].language[language]}
                    </label>
                    <div
                      className={`form-floating ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      {/* <Field as="select" name="userType" className="form-select">
                    <option value="">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">Client</option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  /> */}

                      <Select
                        placeholder={
                          translations["dropdown"].selectLanguage[language]
                        }
                        name="user_language"
                        className="selectpicker"
                        maxMenuHeight={"80px"}
                        value={languages.filter(
                          (item) => item.value === values.user_language
                        )}
                        onChange={(selectedOption) =>
                          setValues({
                            ...values,
                            user_language: selectedOption.value,
                          })
                        }
                        aria-labelledby="dealership-name-quick"
                        // isClearable
                        options={languages}
                        isSearchable
                        styles={{
                          input: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            border: state.isFocused
                              ? "1px solid none"
                              : "1px solid none", // Adjust the border color for focus
                            boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                            borderRadius: ".25rem",
                            paddingTop: "0rem",

                            borderStyle: "none",
                            backgroundColor: "transparent",
                            padding: "0px",
                            minHeight: "20px",
                            marginTop: "-8px",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none", // Hide the indicator separator
                          }),

                          dropdownIndicator: (provided) => ({
                            ...provided,
                            svg: {
                              fill: isDarkMode ? "#ffffff" : "#212529",
                              width: "18px",
                              height: "18px",
                            },
                            paddingRight: "10px",
                            // color: isDarkMode ? "#ffffff" : "#868686",
                          }),

                          menu: (provided) => ({
                            ...provided,
                            margin: "0", // Remove default margin
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: isDarkMode ? "#1f2023" : "white",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                            // Additional styles for placeholder
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: state.isHovered
                              ? "#80bdff"
                              : isDarkMode
                              ? "#1f2023"
                              : "white",
                            ":hover": {
                              backgroundColor: isDarkMode
                                ? "#34DBB4"
                                : "#34DBB4",
                            },
                          }),
                        }}
                      />
                    </div>
                  </div>
                  {values.userType === "1" && (
                    <>
                      <div className="d-flex pt-1 align-items-center ">
                        <div
                          className="form-check my-1"
                          style={{ minWidth: "150px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="pre_form"
                            name="pre_form"
                            checked={values.pre_form}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                pre_form: e.target.checked,
                              });
                            }}
                          />

                          <label htmlFor="pre_form" class="form-check-label">
                            PRE Form
                          </label>
                        </div>
                        <div className="form-check mb-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="gd_form"
                            name="gd_form"
                            checked={values.gd_form}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                gd_form: e.target.checked,
                              });
                            }}
                          />

                          <label htmlFor="gd_form" class="form-check-label">
                            DG Form
                          </label>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <div
                          className="form-check mb-1"
                          style={{ minWidth: "150px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="ca_form"
                            name="ca_form"
                            checked={values.ca_form}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                ca_form: e.target.checked,
                              });
                            }}
                          />

                          <label htmlFor="ca_form" class="form-check-label">
                            CA Form
                          </label>
                        </div>
                        <div className="form-check mb-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="client_submission"
                            name="client_submission"
                            checked={values.client_submission}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                client_submission: e.target.checked,
                              });
                            }}
                          />

                          <label
                            htmlFor="client_submission"
                            class="form-check-label"
                          >
                            Client Submissions
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div
                    className={
                      errors.checktc && touched.checktc
                        ? "form-check"
                        : "form-check mb-3"
                    }
                  >
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      id="checktc"
                      name="checktc"
                    />

                    <label htmlFor="checktc" class="form-check-label">
                      I Agree Terms & Conditions
                    </label>
                    <ErrorMessage
                      name="checktc"
                      component="div"
                      className="error text-danger"
                    />
                  </div> */}
                  <button
                    className="w-100 admin-btn btn-lg btn-primary my-2"
                    type="submit"
                    disabled={loading}
                  >
                    {translations.commonFields.submitBtn[language]}
                  </button>
                  {/* <p className="text-center">
                  Go Back to{" "}
                  <a
                    className="link-primary "
                    style={{ cursor: "pointer" }}
                    onClick={() => window.}
                  >
                    User
                  </a>
                  <Link to="/event-portal" className="text-primary">
                  Event Portal
                </Link>
                </p> */}
                </Form>
              )}
            </Formik>
          )}
        </main>
      </div>
    </div>
  );
};

export default EditUser;
